<template>
        <b-form @submit.stop.prevent="onSubmit" @keyup="onChange" :novalidate="true">
          <b-card no-body class="shadow-lg rounded">
            <div slot="header">
              <strong>Device Form</strong>
            </div>
            <b-card-body>
              <b-card no-body class="shadow-sm rounded">
                <div slot="header">
                  <strong>Device</strong>
                </div>
                <b-card-body>
                  <b-form-group
                    label="Device Code"
                    label-for="code"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('code')"
                    :state="form.errors.has('code')"
                    >
                    <b-form-input disabled id="code" type="text" autocomplete="name" placeholder="Device code"v-model="form.code"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Device UUID *"
                    label-for="uuid"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('uuid')"
                    :state="form.errors.has('uuid')"
                    >
                    <b-form-input id="uuid" type="text" autocomplete="name" placeholder="Device UUID"v-model="form.uuid"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Name *"
                    label-for="name"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('name')"
                    :state="form.errors.has('name')" 
                    >
                    <b-form-input id="name" type="text" autocomplete="name" placeholder="Device Name" v-model="form.name"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Type"
                    label-for="type"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('type')"
                    :state="form.errors.has('type')" 
                    >
                    <b-form-input id="type" type="text" placeholder="Type" v-model="form.type"></b-form-input>
                  </b-form-group>
                  <!-- <b-form-group
                    label="Expiration Date"
                    label-for="expiration_date"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('expiration_date')"
                    :state="form.errors.has('expiration_date')" 
                    >
                    <b-form-input id="expiration_date" autocomplete="expiration_date" placeholder="Expiration Date" v-model="form.expiration_date" type="date" ></b-form-input>
                  </b-form-group> -->
                </b-card-body>
                <Settings :settings="form.devicesetting" @select="onSettings" :is-device="true"/>
                <b-card-body v-if="hasDeviceGroup">
                  <b-form-group
                    label="Device Company"
                    label-for="connectedGroup"
                    :label-cols="2"
                    >
                    <b-form-input id="connectedGroup" v-model="connectedGroup.companies[0].name" disabled></b-form-input>
                  </b-form-group>
                </b-card-body>
              </b-card>
            </b-card-body>
            <b-card-footer>
             <!--  <b-button v-if="form.id" size="sm" variant="danger" v-b-modal="'confirm'+form.id"><i class="fa fa-trash" aria-hidden="true"></i> Remove</b-button> -->
            <!--   <b-modal 
                :id="'confirm'+form.id" 
                size="sm"
                :centered="true"
                button-size="lg"
                :hide-header="true"
                cancel-title="No"
                ok-title="Yes"
                auto-focus-button="cancel"
                @ok="removeDevice"
                >
                Are you sure you want to remove this Device?
              </b-modal> -->
              <b-button v-if="isChange" class=" pull-right" type="submit" size="sm" variant="success"><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{form.id? 'Update':'Save'}}</b-button>
              <b-button class="pull-right mr-2" @click="onReset" size="sm" variant="warning"><i class="fa fa-backward"></i> Reset Form</b-button>
            </b-card-footer>
          </b-card>
        </b-form>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import Company from '../company/Company'
import Settings from '@/components/clients/Settings'

export default AdminExtension.extend({
    name: 'DeviceModify',
    props: {
        device: {
            type: Object,
            require: false,
        },
        deviceGroupId: {
          type: String,
          require: false,
        },
        deviceId: {
          type: String,
          require: false,
        }
    },
    components: {
      Company,
      Settings
    },
    data() {
        return {
          isLocked: false,
          isSaved: true,
          form: new Form({
                  id: null,
                  consistency_token: null,
                  code: null,
                  uuid: null,
                  name: null,
                  fw: null,
                  // expiration_date: null,
                  group: null,
                  devicesetting: null,
              }),
          company_name: null,
          connectedGroup: null,
          isEdit: false,
          isChange: false,
          confirmPassword: null,
        }
    },
    computed: {
      passwordValidation(){
        return this.form.password == this.confirmPassword
      },
      hasDeviceGroup(){
        if (this.connectedGroup){
          return this.connectedGroup > 0
        }
      }
    },
    methods: {
        ...mapActions("common", ["generateCode"]),
        ...mapActions("meds2go/device", ["deviceCreate","deviceUpdate","devicePartial", "getDeviceGroups", "deviceGroupDetail", 'deviceDetail']),
        loadData(){
            if (this.device){

              this.onReset()
              this.form = new Form(this.device)
              this.isEdit = true
              if (this.deviceGroupId){
                this.deviceGroupDetail(this.deviceGroupId).then(response => {
                  this.connectedGroup = response
                  this.form.group = response.id
                }).catch(errors => {
                  console.log(errors)
                })
                this.isChange = true
              }
            }else if (this.deviceGroupId){
              this.deviceGroupDetail(this.deviceGroupId).then(response => {
                this.connectedGroup = response
                this.form.group = response.id
              }).catch(errors => {
                console.log(errors)
              })
              this.generateCode({generate_code:'device'}).then(response=> {
                this.form.code = response
              })

            }else if (this.deviceId){
              this.deviceDetail(this.deviceId).then(response => {
                console.log(response)
                this.form = new Form(response)
                this.isEdit = true
                // this.connectedGroup = response.group
              }).catch(errors => {
                console.log(errors)
              })
            }else{
              this.generateCode({generate_code:'device'}).then(response=> {
                this.form.code = response
              })
            }
        },
        onSubmit() {
            if(this.isEdit){
                this.deviceUpdate(this.form.data()).then(response => {
                    this.$router.push(`/dashboard`)
                }).catch(errors => {
                    this.form.errors.record(errors)
              })
            }   
            else {
                this.deviceCreate(this.form.data()).then(response => {
                    this.form.reset()
                    this.$router.push(`/dashboard`)
                }).catch(errors => {
                    this.form.errors.record(errors)
                })

            }
        },
        onReset(){
            this.form.reset(['code'])
        },
        onChange(){
            this.isChange = true
        },
        removeDevice(){
            this.devicePartial({id: this.form.id, consistency_token: this.form.consistency_token, status: false}).
              then(this.$router.push("/dashboard")).catch(errors => {
                    this.form.errors.record(errors)
            })
        },
        onSettings(data){
          this.form.devicesetting = data
          this.onChange()
        }
    },
    mounted() {
        this.loadData()
    }
})
</script>

